<app-card>
    <header class="header">
        <div class="header__menu">
            <h1>{{name}}</h1>
            <button mat-raised-button>Add {{name | lowercase}}</button>
        </div>
        <div class="header__search">
            <mat-form-field appearance="outline" color="primary">
                <input matInput type="text" placeholder="Search..."  autocomplete="off"/>
            </mat-form-field>
        </div>

    </header>
    <main class="main">
        <app-servers-table [servers]="servers" [selection]="selection"></app-servers-table>
    </main>
</app-card>
