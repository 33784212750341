import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Authenticate } from '../../../../models/authenticate.model';
import { ResponseSingle } from '../../../../models/response.model';
import { Auth } from '../../../../models/auth.model';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ToastService } from '../../../shared/services/toast.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  public loginForm!:FormGroup;
  public appVersion:string = 'v0.0.1'
  private _state!: string | null;

  constructor(
      private _router: Router,
      private _route: ActivatedRoute,
      private _fb:FormBuilder,
      private _authService:AuthService,
      private _toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.loginForm = this._fb.group({
      email: new FormControl('demo.user@3ton.io', [Validators.required, Validators.email]),
      password: new FormControl('HardPassword$%^&*()123', [Validators.required])
    });

    if (this._route.snapshot?.queryParams['state']) {
      this._state = this._route.snapshot?.queryParams['state'];
    }
  }

  onLogin() {

    if (!this.loginForm.valid) {
      return;
    }
    const authenticate: Authenticate = {
      username: this.loginForm.getRawValue().email,
      password: this.loginForm.getRawValue().password,
    };

    const authObs: Observable<ResponseSingle<Auth>> = <Observable<ResponseSingle<Auth>>>this._authService.login(authenticate);

    authObs.subscribe((res:ResponseSingle<Auth>) => {
      const redirectUrl = this._state ? this._state : '/';
      this._router.navigate([redirectUrl]).then(() => this.loginForm.reset());
    }, (error) => {
      console.log(error);

      // this._toast.warning(error.error.error?.message);
      this._toastService.warn(error.error.error?.message)
    });



    // const email = this.loginForm.getRawValue().email;
    // const password = this.loginForm.getRawValue().password;

    // this._sessionService.login(username, password).subscribe( (res:SingleResponse<Session>) => {
    //   const redirectUrl = this._state ? this._state : '/';
    //   this._router.navigate([redirectUrl]).then(() => {
    //     this.loginForm.reset();
    //     this._toastService.success('You are successfully logged in');
    //   });
    // });
  }
}
