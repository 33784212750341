import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { ServersService } from '../../services/servers.service';
import { Server } from '../../../../models/server.model';

@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.scss']
})
export class DashboardMainComponent implements OnInit{
  public masterServers:Server[] = [];

  constructor(
      private _authService:AuthService,
      private _serversService:ServersService,
      private _cd:ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this._serversService.getMasterServers().subscribe( (data) => {
      this.masterServers = data.data;
      this._cd.detectChanges();
    })
  }

  public logout() {
    this._authService.logout();
  }
}
