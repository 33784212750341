<app-card>
    <header class="header">
        <div class="header__menu">
            <h1>Copier settings</h1>

        </div>
        <div class="header__sync">
            <button mat-raised-button disabled>Synchronize</button>
        </div>

    </header>
</app-card>
