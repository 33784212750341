import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { Auth } from '../../../models/auth.model';
import { environment } from '../../../../environments/environment';
import { DateTime } from 'luxon';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Authenticate } from '../../../models/authenticate.model';
import { ResponseError, ResponseSingle } from '../../../models/response.model';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '../../shared/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public session = new BehaviorSubject<Auth>({});

  // private _apiUrl: string = environment.apiUrl;
  private _cookieName: string = '3tonPocAuth';
  private _authCookieExpirationTimeInMinutes = 60;

  constructor(
      private _http:HttpClient,
      private _cookieService:CookieService,
      private _router: Router,
      private _toastService: ToastService
  ) {
    if (this._cookieService.check(this._cookieName)) {
      this.session.next(JSON.parse(this._cookieService.get(this._cookieName)));
    }
  }


  public login(auth:Authenticate): Observable<ResponseSingle<Auth> | ResponseError | ErrorEvent> {
    const now = DateTime.now();
    const mockLogin:ResponseSingle<Auth> = {
      status:"OK",
      data: {
        _id: '15f661bf-63be-4331-b024-84a6fd7ddfe8',
        validTill: now.plus({minutes: 60}).toJSDate(),
        user: {
          _id: '0348ea16-ebcc-4307-abbb-ec9bce0313f9',
          name: 'Demo',
          surname: 'User',
          email: 'demo.user@3ton.io'

        }
      }
    }
    this._handleAuthentication(mockLogin.data);
    this._toastService.success('You are signed in!');
    return of(mockLogin);

    // return this._http
    //     .post<ResponseSingle<Auth>>(`${this._apiUrl}/users/auth`, auth)
    //     .pipe(
    //         tap((response:ResponseSingle<Auth>) => {
    //           this._handleAuthentication(response.data);
    //         }),
    //         tap( (response:ResponseSingle<Auth>) => {
    //           this._toastService.success('You are signed in!')
    //         })
    //     )
  }

  public logout(state?: string) {
    // this._http.delete(`${this._apiUrl}/users/auth`).subscribe()
    this.session.next({});
    this._router.navigate(['/auth/sign-in']);
    this._cookieService.delete(this._cookieName);
    this._toastService.warn('You have been logged out.');
  }

  public redirectToLogin(state?: string) {
    if (state) {
      return this._router.navigate(['/auth/sign-in'], { queryParams: { state } });
    } else {
      return this._router.navigate(['/auth/sign-in']);
    }
  }

  public checkCookieToken():boolean {
    if(this._cookieService.check(this._cookieName)) {
      const cookie:Auth = JSON.parse(this._cookieService.get(this._cookieName))
      return (!!cookie?._id && !!cookie?.user);
    }
    return false;
  }

  public setCookie(session: Auth) {
    const now = DateTime.now();
    const expirationDate = now.plus({ minutes: this._authCookieExpirationTimeInMinutes}).toJSDate();

    this._cookieService.set(this._cookieName, JSON.stringify(session), {
      expires: expirationDate,
      secure: true,
      sameSite: 'None',
      path:'/'
    } );
  }

  private _handleAuthentication(session: Auth): void {
    this.session.next(session);
    this.setCookie(session);
  }

}
