import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Server } from '../../../../models/server.model';

@Component({
  selector: 'app-servers-table',
  templateUrl: './servers-table.component.html',
  styleUrls: ['./servers-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServersTableComponent implements OnInit{
    @Input() servers:Server[] = [];
    @Input() selection:boolean = false;

    public displayedColumns: string[] = ['status', 'selection', 'server', 'name', 'account', 'comment', 'balance', 'profit'];


    constructor() {


    }

    ngOnInit() {
        if(!this.selection) {
            this.displayedColumns.splice(1,1);
        }
    }

    public sortData(event:any) {

    }

}
