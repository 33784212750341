<header class="header">
    <section class="header__menu">
        <button mat-raised-button>Load setup</button>
        <button mat-raised-button>Save settings</button>
        <button mat-raised-button>Guardian</button>
    </section>
    <section class="header__submenu">
        <button
                mat-mini-fab
                matTooltip="Logout"
                color="primary"
                (click)="logout()">
            <mat-icon>power_settings_new</mat-icon>
        </button>
    </section>
</header>
<main>
    <div class="servers">
            <div>
                <app-dashbaord-servers-table name="Master" [servers]="masterServers"></app-dashbaord-servers-table>
            </div>
            <div>
                <app-dashbaord-servers-table name="Slave" [servers]="masterServers" [selection]="true"></app-dashbaord-servers-table>
            </div>
            <div class="servers__copier">
                <app-dashboard-copier></app-dashboard-copier>
            </div>
    </div>
</main>
