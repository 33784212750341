<div class="container">
    <div class="sign-in">
        <h1>Login</h1>
        <p>Sign in with your email address</p>
        <form [formGroup]="loginForm" autocomplete="off" (submit)="onLogin()" class="sign-in__form">
                            <mat-label>Email</mat-label>
            <mat-form-field appearance="outline" color="primary" >

                <input matInput type="email" placeholder="Email" formControlName="email" autocomplete="off"/>
            </mat-form-field>
                            <mat-label>Password</mat-label>
            <mat-form-field appearance="outline" color="primary" >

                <input matInput type="password" placeholder="Password" formControlName="password" autocomplete="off"/>
            </mat-form-field>

            <button [disabled]="loginForm.invalid" mat-raised-button color="primary" type="submit">Continue</button>
        </form>
    </div>
    <div class="info">
        <h2>Join the community and explore the universe of best traders</h2>
    </div>

</div>
