import { NgModule } from '@angular/core';
import { AuthComponent } from './components/auth/auth.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
      AuthComponent, SignInComponent
  ],
  imports: [
    SharedModule
  ]
})
export class AuthModule { }
