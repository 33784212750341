import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './modules/core/components/main/main.component';
import { SignInComponent } from './modules/auth/components/sign-in/sign-in.component';
import { AuthComponent } from './modules/auth/components/auth/auth.component';
import { authGuard } from './modules/auth/services/auth.guard';
import { DashboardMainComponent } from './modules/dashboard/components/dashboard-main/dashboard-main.component';


const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {path: 'sign-in', component: SignInComponent, title: '3TON | Sign in'},
      {path: '', redirectTo: 'sign-in', pathMatch: 'full'}
    ]
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [authGuard],
    children: [
      { path: 'dashboard' , component: DashboardMainComponent, title: '3TON | Dashboard'},
      { path: '', redirectTo: 'dashboard', pathMatch: 'full'}
    ]
  },
  {
    path: '**',
    redirectTo: 'auth',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
