<header class="top-bar">
    <div class="logo">
        <img class="logo__stamp"
             src="assets/icons/logo-stamp.svg"
             alt="3ton logo stamp"/>
        <img class="logo__name"
             src="assets/icons/logo-name.svg"
             alt="3ton logo name"/>
    </div>
</header>
<main class="container">
    <router-outlet></router-outlet>
</main>

