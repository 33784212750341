import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardMainComponent } from './components/dashboard-main/dashboard-main.component';
import { SharedModule } from '../shared/shared.module';
import { DashbaordServersTableComponent } from './components/dashbaord-servers-table/dashbaord-servers-table.component';
import { ServersTableComponent } from './components/servers-table/servers-table.component';
import { DashboardCopierComponent } from './components/dashboard-copier/dashboard-copier.component';



@NgModule({
  declarations: [
    DashboardMainComponent,
    DashbaordServersTableComponent,
    ServersTableComponent,
    DashboardCopierComponent
  ],
  imports: [
    SharedModule
  ]
})
export class DashboardModule { }
