export interface Server {
    _id:string;
    status:ServerStatus;
    server: string;
    enabled:boolean;
    name:string;
    account:number;
    comment:string;
    balance:number;
    profit:number;
    currency: string;

}


export type ServerStatus =   'online' | 'error' | 'offline' | 'disable'
export enum ServerStatuses {
    ONLINE = 'online',
    ERROR = 'error',
    OFFLINE = 'offline',
    DISABLE = 'disable'
}
