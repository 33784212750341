<table
        mat-table class="table"
        [dataSource]="servers" matSort matSortActive="status" matSortDisableClear matSortDirection="desc"
        (matSortChange)="sortData($event)">


    <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header="status" *matHeaderCellDef>Status</th>
        <td  mat-cell *matCellDef="let row">
            <div class="status">
                <span class="status__dot"
                      [class.status__dot--online]="row.status === 'online'"
                      [class.status__dot--offline]="row.status === 'offline'"
                      [class.status__dot--error]="row.status === 'error'"
                      [class.status__dot--disable]="row.status === 'disable'"
                ></span>
                <mat-slide-toggle
                        [hideIcon]="true"
                        [checked]="row.enabled"
                        color="primary"
                >
                </mat-slide-toggle>
            </div>

<!--            {{row.status}}-->
        </td>
    </ng-container>

    <ng-container matColumnDef="selection">
        <th mat-header-cell mat-sort-header="selection" *matHeaderCellDef class="u-th-center"></th>
        <td  mat-cell *matCellDef="let row">
            <mat-checkbox [(ngModel)]="row.selected" color="primary"></mat-checkbox>
        </td>
    </ng-container>

    <ng-container matColumnDef="server">
        <th mat-header-cell mat-sort-header="server" *matHeaderCellDef class="u-th-center">Server</th>
        <td mat-cell *matCellDef="let row">{{row.server}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header="name" *matHeaderCellDef class="u-th-center">Name</th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>


    <ng-container matColumnDef="account">
        <th mat-header-cell mat-sort-header="account" *matHeaderCellDef class="u-th-center">Account</th>
        <td mat-cell *matCellDef="let row">{{row.account}}</td>
    </ng-container>

    <ng-container matColumnDef="comment">
        <th mat-header-cell mat-sort-header="comment" *matHeaderCellDef class="u-th-center">Comment</th>
        <td mat-cell *matCellDef="let row">{{row.comment}}</td>
    </ng-container>

    <ng-container matColumnDef="balance">
        <th mat-header-cell mat-sort-header="balance" *matHeaderCellDef class="u-th-center">Balance</th>
        <td mat-cell *matCellDef="let row">{{row.balance | currency:'USD'}}</td>
    </ng-container>

    <ng-container matColumnDef="profit">
        <th mat-header-cell mat-sort-header="profit" *matHeaderCellDef class="u-th-center">Profit</th>
        <td mat-cell *matCellDef="let row">{{row.profit | currency:'USD'}}</td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="lead-row" *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
