import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ResponseList } from '../../../models/response.model';
import { Server, ServerStatuses } from '../../../models/server.model';

@Injectable({
  providedIn: 'root'
})
export class ServersService {

  constructor(
      private _http:HttpClient,
  ) { }

  public getMasterServers():Observable<ResponseList<Server>> {
    const mockServers: ResponseList<Server> = {
      data: [
        {
          _id: 'abf3e17a-4564-419d-8bf4-bbd96a9c57e6',
          server: 'NazwaICX-Demo1',
          status: ServerStatuses.DISABLE,
          enabled: false,
          name: 'Lorem Ipsum dolor',
          account: 123456,
          comment:'Konto swissquote na emeryturę',
          balance: 2767.97,
          profit: 2767.97,
          currency: 'USD'
        },
        {
          _id: 'f0dffea0-52f9-4bd8-80a3-562153230f4e',
          server: 'NazwaICX-Demo1',
          status: ServerStatuses.ERROR,
          enabled: true,
          name: 'Lorem Ipsum dolor',
          account: 123456,
          comment:'Konto swissquote na emeryturę',
          balance: 2767.97,
          profit: 2767.97,
          currency: 'USD'
        },
        {
          _id: '77d2230c-5c71-4128-8ed4-dc7ad870bd29',
          server: 'NazwaICX-Demo1',
          status: ServerStatuses.DISABLE,
          enabled: false,
          name: 'Lorem Ipsum dolor',
          account: 123456,
          comment:'Konto swissquote na emeryturę',
          balance: 2767.97,
          profit: 2767.97,
          currency: 'USD'
        },
        {
          _id: '11eaa1f8-41e8-4739-86e5-c4d1af4399ce',
          server: 'NazwaICX-Demo1',
          status: ServerStatuses.OFFLINE,
          enabled: true,
          name: 'Lorem Ipsum dolor',
          account: 123456,
          comment:'Konto swissquote na emeryturę',
          balance: 2767.97,
          profit: 2767.97,
          currency: 'USD'
        },
        {
          _id: '79b1cf7d-dc39-4aca-9ed9-188b076e9160',
          server: 'NazwaICX-Demo1',
          status: ServerStatuses.ONLINE,
          enabled: true,
          name: 'Lorem Ipsum dolor',
          account: 123456,
          comment:'Konto swissquote na emeryturę',
          balance: 2767.97,
          profit: 2767.97,
          currency: 'USD'
        },
        {
          _id: '6b23c8bb-29df-419a-b50a-f53ef61eb76a',
          server: 'NazwaICX-Demo1',
          status: ServerStatuses.ONLINE,
          enabled: true,
          name: 'Lorem Ipsum dolor',
          account: 123456,
          comment:'Konto swissquote na emeryturę',
          balance: 2767.97,
          profit: 2767.97,
          currency: 'USD'
        },
        {
          _id: '4d06bdba-85d4-401d-afc9-3d24922592a2',
          server: 'NazwaICX-Demo1',
          status: ServerStatuses.ERROR,
          enabled: true,
          name: 'Lorem Ipsum dolor',
          account: 123456,
          comment:'Konto swissquote na emeryturę',
          balance: 2767.97,
          profit: 2767.97,
          currency: 'USD'
        },
        {
          _id: '4ab55574-c1c0-4fb6-808c-68eba52023b1',
          server: 'NazwaICX-Demo1',
          status: ServerStatuses.OFFLINE,
          enabled: true,
          name: 'Lorem Ipsum dolor',
          account: 123456,
          comment:'Konto swissquote na emeryturę',
          balance: 2767.97,
          profit: 2767.97,
          currency: 'USD'
        },

      ],
      status: "OK",
      total: 8
    }

    return of(mockServers);
   }
}
