import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Server } from '../../../../models/server.model';

@Component({
  selector: 'app-dashbaord-servers-table',
  templateUrl: './dashbaord-servers-table.component.html',
  styleUrls: ['./dashbaord-servers-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashbaordServersTableComponent {
    @Input() name:string = '';
    @Input() servers:Server[] = [];
    @Input() selection:boolean = false;
}

